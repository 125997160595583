import axios from '../axios/axios';
import { CountryDto } from '../models/admin/CountryDto';
import { CountryFilter } from '../models/admin/CountryFilter';
import { CountryRiskDTO } from '../models/admin/CountryRiskDTO';
import { Option } from '../models/shared/Option';
import { Page } from '../pagination/Page';

export class CountryService {
  static getAllCountries(search: string) {
    return axios.post('/country/list-all', {
      name: search
    });
  }

  static changeDisabledStatus(countryDto: CountryDto) {
    return axios.put(
      '/country/change-disabled-status/' + countryDto.countryId,
      countryDto
    );
  }

  static changeRiskScore(country: CountryRiskDTO) {
    return axios.post('/country/change-risk-score', country);
  }

  static async importFileForCountry(file: FormData): Promise<void> {
    await axios.post('/country/import-country-risk', file);
  }

  static async getAllCountriesRisks(): Promise<Option[]> {
    const response = await axios.get('/country/list-all-countries-risk');
    return response.data;
  }
}

export default CountryService;