import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Permission } from '../../../auth/Permission';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import Spinner from '../../../components/Spinner/Spinner';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import PageTable from '../../../components/Table/PageTable';
import { FileDTO } from '../../../models/aml/FileDTO';
import { Roles } from '../../../models/constants';
import { FileDetails } from '../../../models/procedure/FileDetails';
import { GBGResponse } from '../../../models/procedure/GBGResponse';
import KYCProcedure from '../../../models/procedure/KYCProcedure';
import { ShareholderDto } from '../../../models/procedure/ShareholderDto';
import { FileService } from '../../../repository/FileService';
import { ProcedureService } from '../../../repository/ProcedureService';
import { BootstrapDialog, Transition } from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import ProtectedComponent from '../../../shared/components/protected-component/ProtectedComponent';
import ApplicationService from '../../../utils/ApplicationService';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import {
  getProcedureCountryStatusColor,
  getProcedureStatusColor,
  isPendingProcedure
} from '../../../utils/ProcedureColorsUtils';
import { ProcedureStatus } from '../../LandingPage/constants/ProcedureStatus';
import { ProcedureType } from '../enums/ProcedureType';
import { ProcedureType as PT } from '../../LandingPage/constants/ProcedureType';
import AmlyzeResponseView from './AmlyzeResponseView';
import MlroClientConversation from './client-conversation/MlroClientConversation';
import { Note } from './notes/Note';

const KYCProcedureView = (props: {
  kycProcedure: KYCProcedure, reload?: any,
  handleChangeSelectedFileForEdit?: (file: FileDTO) => void,
  shareholders?: ShareholderDto[],
  isClientSecondary?: boolean
}) => {
  const gbgResponse: GBGResponse | undefined = props.kycProcedure?.gbgDetailsResponse;
  const isPending = isPendingProcedure(props.kycProcedure.procedureStatus.label);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showRejectDialog, setShowRejectDialog] = useState<boolean>(false);
  const [rejectedNote, setRejectedNote] = useState<string>('');
  const { t } = useTranslation();

  const handleHideModal = () => setShowRejectDialog(false);

  const handleShowModal = () => setShowRejectDialog(true);

  const onApproveProcedure = () => {
    SwalAlert.confirmationAlert(t('ACCEPT_PROCEDURE'), t('ARE_YOU_SURE_ACCEPT_PROCEDURE'))
      .then((result: any) => {
        const isConfirmed = result.isConfirmed;
        if (isConfirmed) {
          setShowSpinner(true);
          ProcedureService.acceptKycProcedure(props.kycProcedure.procedureId)
            .then(() => {
              setShowSpinner(false);
              props.reload(true);
            })
            .catch(() => SwalAlert.errorAlert(t('ERROR'), t('SOMETHING_WENT_WRONG')))
            .then(() => {
              SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_ACCEPTED_PROCEDURE'));
            });
        }
      });
  };

  const onDeclineProcedure = (e: FormEvent) => {
    e.preventDefault();
    setShowSpinner(true);
    ProcedureService.declineKycProcedure(props.kycProcedure.procedureId, rejectedNote).then(() => {
      setShowSpinner(false);
      handleHideModal();
      props.reload(true);
    })
      .catch(() => SwalAlert.errorAlert(t('ERROR'), t('SOMETHING_WENT_WRONG')))
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_DECLINED_PROCEDURE'));
      });
  };

  const downloadDocument = (file: FileDetails) => {
    if (file.mimeType.includes('image') || file.mimeType.includes('application/pdf')) {
      FileService.getBytesFromDocument(file.fileId).then((data: FileDTO) => {
        if (props.handleChangeSelectedFileForEdit) {
          data.mimeType = file.mimeType;
          props.handleChangeSelectedFileForEdit(data);
        }
      });
    } else {
      FileService.downloadDocuments(file.fileId, file.fileName);
    }
  };

  const resendAmlProcedure = () => {
    setShowSpinner(true);
    ProcedureService.getAmlProcedureForShareholderClient(props?.kycProcedure?.clientId).then((res) => {
      ProcedureService.resendAmlProcedure(res).then(() => {
        setShowSpinner(false);
        props.reload(true);
      })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          SwalAlert.errorAlert(t('ERROR'), errorMessage);
          setShowSpinner(false);
        });
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
      setShowSpinner(false);
    });
  };

  const renderDialogForReject = () => {
    return (
      <BootstrapDialog
        style={{ fontFamily: 'Poppins' }}
        onClose={handleHideModal}
        aria-labelledby='customized-dialog-title'
        open={showRejectDialog}
        className='modalWrapper'
        TransitionComponent={Transition}
        maxWidth={'md'}>
        <form onSubmit={onDeclineProcedure}>
          <BootstrapDialogTitle
            id='customized-dialog-title'
            onClose={handleHideModal}>
            <Typography variant={'h5'} marginRight={5}>{t('ARE_YOU_SURE_TO_DECLINE_PROCEDURE')}</Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} padding={2}>
              <Grid item xs={12}>
                <TextField
                  id='note'
                  label={t('NOTE')}
                  required={true}
                  color='primary'
                  variant={'standard'}
                  type={'text'}
                  multiline
                  maxRows={4}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRejectedNote(e.target.value)}
                  sx={{ width: '100%' }}
                  size='medium'/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='inherit'
              className={'modalCloseButton baseFontFamily'}
              onClick={handleHideModal}>
              {t('CLOSE')}
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              {t('REJECT')}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    );
  };

  const shareholdersHeaderRows = [
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'email',
      label: t('EMAIL'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'companyName',
      label: t('COMPANY_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'percentageOfShares',
      label: t('PERCENTAGE_OF_SHARING'),
      search: false,
      type: 'text',
      variant: 'standard'
    }
  ];

  const shareholdersRows = props.shareholders?.map((element) => {
    return {
      id: element.id,
      link: `/clients/${element.clientId}`,
      values: [
        {
          type: 'text',
          name: 'firstName',
          value: (
            <LightTooltip title={element?.firstName}>
              <span>{element.firstName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'lastName',
          value: (
            <LightTooltip title={element?.lastName}>
              <span>{element.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'email',
          value: (
            <LightTooltip title={element?.email}>
              <span>{element.email}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'companyName',
          value: (
            <LightTooltip title={element?.companyName}>
              <span>{element.companyName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'percentageOfShares',
          value: (
            <LightTooltip title={element?.percentageOfShares}>
              <span>{element.percentageOfShares}</span>
            </LightTooltip>
          )
        }
      ]
    };
  });

  return (
    <Box>
      <Spinner showSpinner={showSpinner}/>
      <Box className={'procedure-title-wrapper'}>
        <Box>
        </Box>
        <Box>
          <Typography color={getProcedureStatusColor(props.kycProcedure?.procedureStatus.label)}>
            <b>{props.kycProcedure?.procedureStatus.label}</b>
          </Typography>
        </Box>
      </Box>
      <Box className={'procedure-content'}>
        <Box>
          {props.kycProcedure.rejectedNote && <Typography>
            <b>{t('REJECTION_NOTE')}:</b> {props.kycProcedure?.rejectedNote}
          </Typography>}
        </Box>
        <Box>
          <Typography className='country-status-label'>
            <b>{t('COUNTRY_OF_BIRTH')}:</b> {props.kycProcedure?.country?.countryName}
          </Typography>
          <Typography className='country-status-value'
                      color={getProcedureCountryStatusColor(
                        props.kycProcedure?.country?.countryRisk)}><b> ({props.kycProcedure?.country?.countryRiskLabel})</b></Typography>
        </Box>
        <Box>
          <Typography className='country-status-label'>
            <b>{t('COUNTRY_OF_RESIDENCE')}:</b> {props.kycProcedure?.countryOfResidence?.countryName}
          </Typography>
          <Typography className='country-status-value'
                      color={getProcedureCountryStatusColor(
                        props.kycProcedure?.countryOfResidence?.countryRisk)}><b> ({props.kycProcedure?.countryOfResidence?.countryRiskLabel})</b></Typography>
        </Box>
        <Box>
          <Typography className='country-status-label'>
            <b>{t('CITIZENSHIP')}:</b> {props.kycProcedure?.citizenship?.countryName}
          </Typography>
          <Typography className='country-status-value'
                      color={getProcedureCountryStatusColor(
                        props.kycProcedure?.citizenship?.countryRisk)}><b> ({props.kycProcedure?.citizenship?.countryRiskLabel})</b></Typography>
        </Box>
        <Typography>
          <b>{t('CITY')}:</b> {props.kycProcedure?.city}
        </Typography>
        <Typography>
          <b>{t('ZIP_CODE')}:</b> {props.kycProcedure?.zipCode}
        </Typography>
        <Typography>
          <b>{t('ADDRESS_LINE_ONE')}:</b> {props.kycProcedure?.addressLineOne}
        </Typography>
        <Typography>
          <b>{t('ADDRESS_LINE_TWO')}:</b> {props.kycProcedure?.addressLineTwo}
        </Typography>
        {props.kycProcedure?.procedureType === PT.LEGAL_ENTITY &&
          <>
            <Typography><b>{t(
              'IS_USA_TAX_RESIDENT')}: </b> {props?.kycProcedure?.isUsaTaxResident === true ? 'Yes' : 'No'}
            </Typography>
            <Typography><b>{t(
              'IS_SOLE_OWNER')}: </b> {props?.kycProcedure?.isSoleOwner === true ? 'Yes' : 'No'}
            </Typography>
          </>
        }
        <Typography>
          <b>{t('NOTE')}:</b> {props.kycProcedure?.note}
        </Typography>
        <Typography>
          <b>{t('REVIEWED_BY')}:</b> {props.kycProcedure?.reviewedByUser}
        </Typography>
      </Box>
      <Grid container className={'procedure-time-wrapper'} marginTop={1} spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon
              className={'procedure-icon'}/><b>{t('DATE_CREATED')}: </b> &ensp; {DateTimeUtils.formatDate(props.kycProcedure?.dateCreated!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/>
            <b>{t('DATE_MODIFIED')}: </b> &ensp; {DateTimeUtils.formatDate(props.kycProcedure?.dateModified!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/>
            <b>{t('VALID_FROM')}:</b> &ensp; {DateTimeUtils.formatDate(props.kycProcedure?.validFrom!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/>
            <b>{t('VALID_TO')}:</b>&ensp;  {DateTimeUtils.formatDate(props.kycProcedure?.validTo!)}
          </Box>
        </Grid>
      </Grid>
      {props.kycProcedure?.ipInfo && (
        <Box marginY={3}>
          <Typography variant='h6'>
            <b>{t('IP_ADDRESS_INFO')}:</b>
          </Typography>
          <Typography>
            <b>{t('IP')}:</b>
            {' '}{props.kycProcedure?.ipInfo?.ip}
          </Typography>
          <Typography>
            <b>{t('COUNTRY_INFO')}:</b>
            {' '}{props.kycProcedure?.ipInfo?.country === null ? t(
            'NO_INFO') : props.kycProcedure?.ipInfo?.country + ', ' + props.kycProcedure?.ipInfo?.countryCode}
          </Typography>
          <Typography>
            <b>{t('CITY_INFO')}:</b>
            {' '}{props.kycProcedure?.ipInfo?.city === null ? t('NO_INFO') : props.kycProcedure?.ipInfo?.city + ', ' + props.kycProcedure?.ipInfo?.postal}
          </Typography>
        </Box>
      )}
      <Box marginY={3}>
        <Typography variant='h6'><b>{t('TERMS_AND_CONDITIONS')}</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Typography marginY={1}><b>{t('CORRECT_PERSONAL_INFO')}:</b> {props.kycProcedure.termsAndConditionsDto?.acceptedPolicy ? t('CONFIRMED') : t(
            'NOT_CONFIRMED')}
          </Typography>
          <Typography marginY={1}><b>{t('S2T_ABLE_TO_CONTACT')}:</b> {props.kycProcedure.termsAndConditionsDto?.acceptedRepresentativeContact ? t(
            'CONFIRMED') : t('NOT_CONFIRMED')}
          </Typography>
          <Typography marginY={1}><b>{t('INFO_SEND_TO_THIRD_PART')}:</b> {props.kycProcedure.termsAndConditionsDto?.acceptedInfoThirdParty ? t('CONFIRMED') : t(
            'NOT_CONFIRMED')}
          </Typography>
          <Typography marginY={1}><b>{t('PEP_CONFIRM')}:</b> {props.kycProcedure.termsAndConditionsDto?.acceptedIsPep ? t('CONFIRMED') : t('NOT_CONFIRMED')}
          </Typography>
          {props.kycProcedure?.procedureType === PT.INDIVIDUAL &&
            <Typography marginY={1}><b>{t('BENEFICIARY_CONFIRMATION')}:</b> {props.kycProcedure.termsAndConditionsDto?.acceptedBeneficiaries ? t(
              'CONFIRMED') : t(
              'NOT_CONFIRMED')}
            </Typography>
          }
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography variant='h6'><b>{t('GBG_RESPONSE')}</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('ISSUE_DATE')}:</b>{gbgResponse?.issueDate ? gbgResponse?.issueDate : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('PERSONAL_NUMBER')}:</b> {gbgResponse?.personalNumber ? gbgResponse?.personalNumber : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('DOCUMENT_NUMBER')}:</b> {gbgResponse?.documentNumber ? gbgResponse?.documentNumber : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={6} xl={6} marginY={1}>
            <Typography><b>{t('SEX')}:</b>{gbgResponse?.sex ? gbgResponse?.sex : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('DOCUMENT_TYPE')}:</b> {gbgResponse?.documentType ? gbgResponse?.documentType : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('ISSUING_STATE_NAME')}:</b> {gbgResponse?.issuingStateName ? gbgResponse?.issuingStateName : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('DOCUMENT_OVERALL_VALIDATION')}:</b>{gbgResponse?.documentOverallValidation ? gbgResponse?.documentOverallValidation : t(
              'NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography>
              <b>{t('DOCUMENT_BLOCKING_POLICY')}:</b> {gbgResponse?.documentType ? gbgResponse.documentType : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography> <b>{t('DOCUMENT_EXPIRY')}:</b>{gbgResponse?.documentExpiry ? gbgResponse?.documentExpiry : t('NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
            <Typography><b>{t('ACCUMULATIVE_LIVENESS_RESULT')}:</b> {gbgResponse?.accumulativeLivenessResult ? gbgResponse?.accumulativeLivenessResult : t(
              'NO_MATCH_IS_FOUND')}
            </Typography>
          </Grid>
          <Grid item xs={12} marginY={1}>
            <Typography><b>{t('GBG_WEBSITE')}: </b>
              <Link to={process.env.REACT_APP_GBG_WEBSITE!} target='_blank'>{t('GBG_WEBSITE')}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography variant='h6'><b>{t('FILES')}:</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Grid item xs={12} marginY={1}>
            <Box className={'procedure-list'}>
              {props.kycProcedure?.files?.map((file) => {
                return (
                  <Box key={file.fileId}>
                    <Box>{file.fileType}: </Box>
                    <Box marginX={1}
                         className={'action-pointer procedure-question-file'}
                         onClick={() => downloadDocument(file)}
                         title={`download ${file.fileName}`}>
                      <SimCardDownloadOutlinedIcon className={'procedure-file-icon'}/>{file.fileName}
                    </Box>
                  </Box>);
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto &&
        <Box marginY={3}>
          <Typography variant='h6'><b>{t('LEGAL_ENTITY_DATA')}:</b></Typography>
          <Grid container className={'procedure-data-wrapper'}>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('NAME')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.name}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('FORM')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.form}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('REGISTRATION_NUMBER')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.registrationNumber}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('DATE_OF_INCORPORATION')}: </b> {DateTimeUtils.formatDate(
                props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.dateOfIncorporation)}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('REGISTERED_ADDRESS')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.registeredAddress}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('REGISTERED_COUNTRY')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.registeredCountry}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('BUSINESS_ADDRESS')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.businessAddress}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('PHONE_NUMBER')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('EMAIL')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.email}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('COMPANY_WEBSITE')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.companyWebsite}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('TAX_IDENTIFICATION_NUMBER')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.taxIdentificationNumber}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.representativeAnswers &&
        <Box marginY={3}>
          <Typography variant='h6'><b>{t('REPRESENTATIVE_DATA')}:</b></Typography>
          <Grid container className={'procedure-data-wrapper'}>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('WORKING_POSITION')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.representativeAnswers?.workingPosition}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('PHONE_NUMBER')}: </b>{props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.representativeAnswers?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('EMAIL')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.representativeAnswers?.email}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t(
                'PERCENTAGE_OF_SHARING')}: </b> {props?.kycProcedure?.kycProcedureLegalEntityAnswersDto?.representativeAnswers?.percentageOfSharing}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      {props?.kycProcedure?.shareholder && props?.isClientSecondary &&
        <Box marginY={3}>
          <Typography variant='h6'><b>{t('SHAREHOLDER_DETAILS')}:</b></Typography>
          <Grid container className={'procedure-data-wrapper'}>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('FIRST_NAME')}: </b>{props?.kycProcedure?.shareholder?.firstName}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('LAST_NAME')}: </b>{props?.kycProcedure?.shareholder?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('EMAIL')}: </b>{props?.kycProcedure?.shareholder?.email}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('PHONE_NUMBER')}: </b>{props?.kycProcedure?.shareholder?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('COMPANY_NAME')}: </b>{props?.kycProcedure?.shareholder?.companyName}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('PERCENTAGE_OF_SHARING')}: </b>{props?.kycProcedure?.shareholder?.percentageOfShares}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('SHAREHOLDER_ROLE')}: </b>{props?.kycProcedure?.shareholder?.shareholderRole}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('SHAREHOLDER_TYPE')}: </b>{props?.kycProcedure?.shareholder?.shareholderType}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      {props?.kycProcedure?.shareHolderAmlyzeResponseDto === null && props?.isClientSecondary && ApplicationService.hasRole(
          Roles.MLRO) && props?.kycProcedure?.procedureStatus.status !== ProcedureStatus.NEW &&
        <Box>
          <Typography variant='h6'>
            <b>{t('AMLYZE_RESPONSE')}</b>
            <Button
              color='primary'
              variant='contained'
              sx={{ marginLeft: 75 }}
              onClick={() => resendAmlProcedure()}
            >
              {t('SEND')}
            </Button>
          </Typography>
          <Grid container className={'procedure-data-wrapper'}>
            {t('NO_INFO')}
          </Grid>
        </Box>
      }
      {props?.kycProcedure?.shareHolderAmlyzeResponseDto && props?.isClientSecondary &&
        <Box marginY={3}>
          <Typography variant='h6'><b>{t('AMLYZE_RESPONSE')}:</b></Typography>
          <Grid container className={'procedure-data-wrapper'}>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t('AMLYZE_CLIENT_ID')}: </b>{props?.kycProcedure?.shareHolderAmlyzeResponseDto?.externalId}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t(
                'SANCTIONED')}: </b>{props?.kycProcedure?.shareHolderAmlyzeResponseDto?.sanctioned === true ? 'True' : props?.kycProcedure?.shareHolderAmlyzeResponseDto?.sanctioned === false ? 'False' : '/'}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t(
                'PEP')}: </b> {props?.kycProcedure?.shareHolderAmlyzeResponseDto?.pep === true ? 'True' : props?.kycProcedure?.shareHolderAmlyzeResponseDto?.pep === false ? 'False' : '/'}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8} xl={6} marginY={1}>
              <Typography><b>{t(
                'SCREENING_MATCH')}: </b> {props?.kycProcedure?.shareHolderAmlyzeResponseDto?.screeningMatch === true ? 'True' : props?.kycProcedure?.shareHolderAmlyzeResponseDto?.screeningMatch === false ? 'False' : '/'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      {props?.shareholders && props?.shareholders?.length > 0 &&
        <Box marginY={3}>
          <Typography variant='h6'><b>{t('SHAREHOLDERS')}:</b></Typography>
          <Grid container className={'procedure-data-wrapper'}>
            <Grid item xs={12} marginY={1}>
              <Box className={'procedure-list'}>
                <PageTable
                  headerRows={shareholdersHeaderRows}
                  rows={shareholdersRows}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      <Grid marginY={3}>
        <Typography variant='h6'><b>{t('NOTES')}:</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Note procedureId={props.kycProcedure.procedureId} procedureType={ProcedureType.KYC_PROCEDURE}/>
        </Grid>
      </Grid>
      <Grid marginY={3}>
        <Typography variant='h6'><b>{t('CONVERSATION')}:</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <MlroClientConversation procedureId={props.kycProcedure.procedureId}
                                  clientName={props.kycProcedure.clientName}
                                  procedureType={ProcedureType.KYC_PROCEDURE}
                                  handleChangeSelectedFileForEdit={props.handleChangeSelectedFileForEdit}/>
        </Grid>
      </Grid>
      {isPending &&
        <ProtectedComponent permission={Permission.FE_CAN_APPROVE_REJECT_PROCEDURES}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
            <Button color='primary' variant='contained' onClick={onApproveProcedure}>Accept</Button>
            <Button color='error' variant='contained' sx={{ marginLeft: 2 }}
                    onClick={handleShowModal}>{t('REJECT')}</Button>
          </Grid>
        </ProtectedComponent>}
      {renderDialogForReject()}
    </Box>);
};
export default KYCProcedureView;
