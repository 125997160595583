import Swal from 'sweetalert2';

const SwalAlert: any = {
  successfulAlert: (title: string, text: string, footer?: string, showConfirmButton?: boolean): void => {
    Swal.fire({
      title: title,
      text: text,
      icon: 'success',
      confirmButtonColor: '#0e387a',
      footer: footer,
      showConfirmButton: showConfirmButton === undefined ? true : showConfirmButton
    });
  },
  errorAlert: (title: string, text: string): void => {
    Swal.fire({
      icon: 'error',
      confirmButtonColor: '#0e387a',
      title: title,
      text: text
    });
  },

  confirmationAlert: (title: string, text: string) => {
    return Swal.fire({
      icon: 'question',
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonColor: '#0e387a',
      confirmButtonText: 'Accept',
      showDenyButton: true,
      denyButtonText: 'Decline'
    });
  },
  infoAlert: (title: string, text: string): void => {
    Swal.fire({
      icon: 'info',
      confirmButtonColor: '#0e387a',
      title: title,
      text: text
    });
  }
};
export default SwalAlert;
