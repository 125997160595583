import axios from '../axios/axios';

const KYCProcedureService = {
  async existsByClient(id: number): Promise<boolean> {
    const response = await axios.get(`/kyc-procedure/exists-by-client?id=${id}`);
    return response.data;
  }
};

export default KYCProcedureService;
