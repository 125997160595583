import axios from '../axios/axios';

export class ClientTokenService {
  static async isTokenExpired(
    token: string
  ): Promise<boolean> {
    const response = await axios.get(
      '/clientToken/is-token-expired?token=' + token
    );

    return response.data;
  }
}
