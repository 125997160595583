import axios from '../axios/axios';
import { FileDTO } from '../models/aml/FileDTO';
import { ClientDto } from '../models/clients/ClientDto';
import { AMLProcedure } from '../models/procedure/AMLProcedure';
import KYCProcedure from '../models/procedure/KYCProcedure';
import { ProcedureTokenResponse } from '../models/procedure/ProcedureTokenResponse';
import { ProcedureTypeShortName } from '../models/procedure/ProcedureTypeShortName';

export class ProcedureService {
  static async getActiveKYCProcedureForClient(clientId: string): Promise<KYCProcedure> {
    const response = await axios.get(
      `/kyc-procedure/active/client/${clientId}`
    );
    return response.data;
  }

  static async getActiveAMLProcedureForClient(clientId: string): Promise<AMLProcedure> {
    const response = await axios.get(
      `/aml-procedure/active/client/${clientId}`
    );
    return response.data;
  }

  static async getAllKYCProcedureForClient(id: string): Promise<KYCProcedure[]> {
    const response = await axios.get(`/kyc-procedure/client/${id}`);
    return response.data;
  }

  static async getAllAMLProcedureForClient(id: string): Promise<AMLProcedure[]> {
    const response = await axios.get(`/aml-procedure/client/${id}`);
    return response.data;
  }

  static async getAMLProcedureById(id: string): Promise<AMLProcedure> {
    const response = await axios.get(`/aml-procedure/${id}`);
    return response.data;
  }

  static async getKYCProcedureById(id: string): Promise<KYCProcedure> {
    const response = await axios.get(`/kyc-procedure/${id}`);
    return response.data;
  }

  static async acceptKycProcedure(kycProcedureId: number) {
    const response = await axios.put(`/kyc-procedure/${kycProcedureId}/accept`);
    return response.data;
  }

  static async declineKycProcedure(kycProcedureId: number, rejectedNote: string) {
    const response = await axios.put(`/kyc-procedure/${kycProcedureId}/decline`, { rejectedNote });
    return response.data;
  }

  static async acceptAmlProcedure(amlProcedureId: number) {
    const response = await axios.put(`/aml-procedure/${amlProcedureId}/accept`);
    return response.data;
  }

  static async declineAmlProcedure(amlProcedureId: number, rejectedNote: string) {
    const response = await axios.put(`/aml-procedure/${amlProcedureId}/decline`, { rejectedNote });
    return response.data;
  }

  static async getGbgDocuments(id: number): Promise<FileDTO[]> {
    const response = await axios.post(`/kyc-procedure/gbg-documents/${id}`);
    return response.data;
  }

  static async getDetailsForAMLToken(clientId: number): Promise<ProcedureTokenResponse> {
    const response = await axios.get(`/aml-procedure/active/client-token/${clientId}`);
    return response.data;
  }

  static async getDetailsForKYCToken(clientId: number): Promise<ProcedureTokenResponse> {
    const response = await axios.get(`/kyc-procedure/active/client-token/${clientId}`);
    return response.data;
  }

  static async reInitializeProcedure(personRequest: ClientDto, procedure: ProcedureTypeShortName): Promise<ProcedureTokenResponse> {
    const response = await axios.post(`/client/initiate-procedure`, personRequest, { headers: { procedure } });
    return response.data;
  }

  static async resendAmlProcedure(amlProcedureId: number): Promise<void> {
    await axios.post(`/aml-procedure/resend`, {
      amlProcedureId: amlProcedureId
    });
  }

  static async getAmlProcedureForShareholderClient(clientId: number): Promise<number> {
    const response = await axios.get(`/aml-procedure/aml-procedure-for-shareholder-client/${clientId}`);
    return response.data;
  }
}