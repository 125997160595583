import axios from '../axios/axios';
import { AMLQuestionnaireRequest } from '../models/clientQuestionnaires/AMLQuestionnaireRequest';

const QuestionsService = {
  getAllQuestionsType: () => {
    return axios.get('/metamodel/aml-question-type/public/get-all');
  },
  getAllAMLQuestionsByGrope: (questionGroupId: any) => {
    return axios.post(
      '/metamodel/aml-question/aml-questions',
      {},
      {
        headers: {
          questionGroupId
        }
      }
    );
  },
  getActiveAMLQuestionsByGroup: (type: string) => {
    return axios.post(
      '/metamodel/aml-questionnaire/get-active-group',
      {},
      {
        headers: {
          type
        }
      }
    );
  },
  deleteQuestion: (id: number) => {
    return axios.delete(`metamodel/aml-question/delete/${id}`);
  },
  createDraftQuestionnaire: (questionGroupId: number) => {
    return axios.post(`/metamodel/aml-questionnaire/copy/${questionGroupId}`);
  },
  updateQuestionnaire: (questionGroupId: string, questionData: AMLQuestionnaireRequest) => {
    return axios.post(`/metamodel/aml-questionnaire/update/${questionGroupId}`, questionData);
  },
  deleteDraftQuestionnaire: (questionGroupId: number) => {
    return axios.post(`/metamodel/aml-questionnaire/delete/${questionGroupId}`);
  },
  getDraftId: (type: string) => {
    return axios.post('/metamodel/aml-questionnaire/get-draft-id', null, {
      headers: {
        'type': type
      }
    });
  }
};
export default QuestionsService;
