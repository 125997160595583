import axios from '../axios/axios';
import { ShareholderDto } from '../models/procedure/ShareholderDto';

const ShareholderService = {
  async allShareholdersByKycProcedure(id: number): Promise<ShareholderDto[]> {
    const response = await axios.get('/shareholder/all-by-kyc-procedure/' + id);
    return response.data;
  }
};

export default ShareholderService;