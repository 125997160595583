import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Permission } from '../../../auth/Permission';
import Spinner from '../../../components/Spinner/Spinner';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../../models/aml/FileDTO';
import { AMLQuestionAnswerFiles } from '../../../models/procedure/AMLAnswerFiles';
import { AMLProcedure } from '../../../models/procedure/AMLProcedure';
import { AMLQuestionAnswer } from '../../../models/procedure/AMLQuestionAnswer';
import { FileDetails } from '../../../models/procedure/FileDetails';
import { FileService } from '../../../repository/FileService';
import { ProcedureService } from '../../../repository/ProcedureService';
import { BootstrapDialog, Transition } from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import ProtectedComponent from '../../../shared/components/protected-component/ProtectedComponent';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import { getProcedureStatusColor, isPendingProcedure } from '../../../utils/ProcedureColorsUtils';
import { ProcedureType } from '../enums/ProcedureType';
import AmlyzeResponseView from './AmlyzeResponseView';
import MlroClientConversation from './client-conversation/MlroClientConversation';
import { Note } from './notes/Note';
import { ErrorHandler } from 'utils/ErrorHandler';
import ApplicationService from 'utils/ApplicationService';
import { Roles } from 'models/constants';

const AMLProcedureView = (props: {
  amlProcedure: AMLProcedure, reload?: any, handleChangeSelectedFileForEdit?: (file: FileDTO) => void
}) => {

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showRejectDialog, setShowRejectDialog] = useState<boolean>(false);
  const [rejectedNote, setRejectedNote] = useState<string>('');

  const isPending = isPendingProcedure(props.amlProcedure.procedureStatus.status);

  const handleHideModal = () => setShowRejectDialog(false);

  const handleShowModal = () => setShowRejectDialog(true);

  const resendAmlProcedure = () => {
    setShowSpinner(true);
    ProcedureService.resendAmlProcedure(props.amlProcedure.procedureId).then(() => {
      setShowSpinner(false);
      props.reload(true);
    })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        SwalAlert.errorAlert(t('ERROR'), errorMessage);
        setShowSpinner(false);
      });
  };

  const { t } = useTranslation();

  const onApproveProcedure = () => {
    SwalAlert.confirmationAlert(
      t('ACCEPT_PROCEDURE'),
      t('ARE_YOU_SURE_ACCEPT_PROCEDURE')
    ).then((result: any) => {
      const isConfirmed = result.isConfirmed;
      if (isConfirmed) {
        setShowSpinner(true);
        ProcedureService.acceptAmlProcedure(props.amlProcedure.procedureId)
          .then(() => {
            setShowSpinner(false);
            props.reload(true);
          })
          .catch(() => SwalAlert.errorAlert(t('ERROR'), t('SOMETHING_WENT_WRONG')))
          .then(() => {
            SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_ACCEPTED_PROCEDURE'));
          });
      }
    });
  };

  const onDeclineProcedure = (e: FormEvent) => {
    e.preventDefault();
    setShowSpinner(true);
    ProcedureService.declineAmlProcedure(props.amlProcedure.procedureId, rejectedNote).then(() => {
      setShowSpinner(false);
      handleHideModal();
      props.reload(true);
    })
      .catch(() => SwalAlert.errorAlert(t('ERROR'), t('SOMETHING_WENT_WRONG')))
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_DECLINED_PROCEDURE'));
      });
  };

  const downloadDocument = (file: FileDetails) => {
    if (file.mimeType.includes('image') || file.mimeType.includes('application/pdf')) {
      FileService.getBytesFromDocument(file.fileId).then((data: FileDTO) => {
        if (props.handleChangeSelectedFileForEdit) {
          data.mimeType = file.mimeType;
          props.handleChangeSelectedFileForEdit(data);
        }
      });
    } else {
      FileService.downloadDocuments(file.fileId, file.fileName);
    }
  };

  const renderDialogForReject = () => {
    return (
      <BootstrapDialog
        style={{ fontFamily: 'Poppins' }}
        onClose={handleHideModal}
        aria-labelledby='customized-dialog-title'
        open={showRejectDialog}
        className='modalWrapper'
        TransitionComponent={Transition}
        maxWidth={'md'}>
        <form onSubmit={onDeclineProcedure}>
          <BootstrapDialogTitle id='customized-dialog-title' onClose={handleHideModal}>
            <Typography variant={'h5'} marginRight={5}>{t('ARE_YOU_SURE_TO_DECLINE_PROCEDURE')}</Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} padding={2}>
              <Grid item xs={12}>
                <TextField
                  id='note'
                  label={t('NOTE')}
                  multiline
                  maxRows={4}
                  required={true}
                  color='primary'
                  variant={'standard'}
                  type={'text'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRejectedNote(e.target.value)}
                  sx={{ width: '100%' }}
                  size='medium'/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='inherit'
              className={'modalCloseButton baseFontFamily'}
              onClick={handleHideModal}>
              {t('CLOSE')}
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              {t('REJECT')}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    );
  };

  const renderAmlQuestion = (answer: AMLQuestionAnswer, index: number) => {
    return (
      <Box key={index} marginBottom={2}>
        <Typography className={'procedure-question-title'}>
          <b>
            {index + 1}. {answer.question}
          </b>
        </Typography>
        <Box className={'procedure-question-answer'}>
          - {answer.answer}
          {answer.files && answer.files.length > 0 && (
            <Box className={'procedure-question-file-wrapper'}>
              <Typography>
                <b>{t('FILES')}:</b>
              </Typography>
              {answer.files &&
                answer.files.map(
                  (filesWrapper: AMLQuestionAnswerFiles, index) => {
                    return (
                      <Box marginLeft={2} key={index}>
                        <span className={'procedure-question-title'}>
                          {filesWrapper.title}:
                        </span>
                        <Box className={'procedure-list-item'}>
                          {filesWrapper.files.map((file: FileDetails) => {
                            return (
                              <Box
                                marginX={1}
                                className={'procedure-question-file action-pointer'}
                                key={file.fileId}
                                onClick={() => downloadDocument(file)}>
                                <SimCardDownloadOutlinedIcon
                                  className={'procedure-file-icon'}/>
                                {file.fileName}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  }
                )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Spinner showSpinner={showSpinner}/>
      <Box className={'procedure-title-wrapper'}>
        <Box>
          <Typography>
            <b>{t('REVIEWED_BY')}:</b> {props.amlProcedure?.reviewedByUser}
          </Typography>
        </Box>
        <Box>
          <Typography
            color={getProcedureStatusColor(
              props.amlProcedure?.procedureStatus.label
            )}>
            <b>{props.amlProcedure?.procedureStatus.label.replace('_', ' ')}</b>
          </Typography>
        </Box>
      </Box>
      <Box className={'procedure-content'}>
        {props.amlProcedure?.rejectedNote && <Typography>
          <b>{t('REJECTION_NOTE')}:</b> {props.amlProcedure?.rejectedNote}
        </Typography>}
        <Typography>
          <b>{t('NOTE')}:</b> {props.amlProcedure?.note}
        </Typography>
      </Box>
      <Grid container className={'procedure-time-wrapper'} marginTop={1} spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/>
            <b>{t('DATE_CREATED')}: </b> &ensp;{' '}
            {DateTimeUtils.formatDate(props.amlProcedure?.dateCreated!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/>{' '}
            <b>{t('DATE_MODIFIED')}: </b> &ensp;{' '}
            {DateTimeUtils.formatDate(props.amlProcedure?.dateModified!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/> <b>{t('VALID_FROM')}:</b>{' '}
            &ensp; {DateTimeUtils.formatDate(props.amlProcedure?.validFrom!)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginTop={2}>
          <Box className={'procedure-time'}>
            <AccessTimeIcon className={'procedure-icon'}/> <b>{t('VALID_TO')}:</b>
            &ensp; {DateTimeUtils.formatDate(props.amlProcedure?.validTo!)}
          </Box>
        </Grid>
      </Grid>

      <Box marginY={3}>
        <Typography variant='h6'>
          <b>{t('AMLYZE_RESPONSE')}</b>
          {ApplicationService.hasRole(Roles.MLRO) && props.amlProcedure?.amlyzeResponse === null && (
            <Button
              color='primary'
              variant='contained'
              sx={{ marginLeft: 75 }}
              onClick={() => resendAmlProcedure()}
            >
              {t('SEND')}
            </Button>
          )}
        </Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Grid item>
            {props.amlProcedure?.amlyzeResponse === null ? t('NO_INFO') : <AmlyzeResponseView amlyzeResponse={props.amlProcedure?.amlyzeResponse}/>}
          </Grid>
          <Grid item xs={12}>
            <Typography><b>{t('AMLYZE_WEBSITE')}: </b>
              <Link to={process.env.REACT_APP_AMLYZE_WEBSITE!} target='_blank'>{t('AMLYZE_WEBSITE')}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {props.amlProcedure?.ipInfo && (
        <Box marginY={3}>
          <Typography variant='h6'>
            <b>{t('IP_ADDRESS_INFO')}:</b>
          </Typography>
          <Typography>
            <b>{t('IP')}:</b>
            {' '}{props.amlProcedure?.ipInfo?.ip}
          </Typography>
          <Typography>
            <b>{t('COUNTRY_INFO')}:</b>
            {' '}
            {props.amlProcedure?.ipInfo?.country === null ? t('NO_INFO') : props.amlProcedure?.ipInfo?.country + ', ' + props.amlProcedure?.ipInfo?.countryCode}
          </Typography>
          <Typography>
            <b>{t('CITY_INFO')}:</b>
            {' '}{props.amlProcedure?.ipInfo?.city === null ? t('NO_INFO') : props.amlProcedure?.ipInfo?.city + ', ' + props.amlProcedure?.ipInfo?.postal}
          </Typography>
        </Box>
      )}
      {props.amlProcedure.amlQuestionAnswerResponseList && (
        <Box marginY={3}>
          <Typography variant='h6'>
            <b>{t('QUESTIONS_ANSWER')}:</b>
          </Typography>
          {props.amlProcedure.amlQuestionAnswerResponseList.length > 0 ? (
            <Grid container className={'procedure-data-wrapper'}>
              <Grid item xs={12} marginY={1}>
                <Box className={''}>
                  {props.amlProcedure.amlQuestionAnswerResponseList &&
                    props.amlProcedure.amlQuestionAnswerResponseList.map(
                      (answer: AMLQuestionAnswer, index) => {
                        return renderAmlQuestion(answer, index);
                      }
                    )}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Typography marginLeft={2} marginTop={1}>
              <i>{t('EMPTY_LIST')}:</i>
            </Typography>
          )}
        </Box>
      )}
      <Grid marginY={3}>
        <Typography variant='h6'><b>{t('NOTES')}:</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <Note procedureId={props.amlProcedure.procedureId} procedureType={ProcedureType.AML_PROCEDURE}/>
        </Grid>
      </Grid>
      <Grid marginY={3}>
        <Typography variant='h6'><b>{t('CONVERSATION')}:</b></Typography>
        <Grid container className={'procedure-data-wrapper'}>
          <MlroClientConversation procedureId={props.amlProcedure.procedureId} clientName={props.amlProcedure.clientName}
                                  procedureType={ProcedureType.AML_PROCEDURE}
                                  handleChangeSelectedFileForEdit={props.handleChangeSelectedFileForEdit}/>
        </Grid>
      </Grid>
      <Grid container>
        {isPending && (
          <ProtectedComponent permission={Permission.FE_CAN_APPROVE_REJECT_PROCEDURES}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={onApproveProcedure}>{t('ACCEPT')}</Button>
              <Button color='error' variant='contained' sx={{ marginLeft: 2 }} onClick={handleShowModal}>{t('REJECT')}</Button>
            </Grid>
          </ProtectedComponent>
        )}
      </Grid>
      {renderDialogForReject()}
    </Box>
  );
};
export default AMLProcedureView;
