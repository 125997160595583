import axios from '../axios/axios';
import { FileDTO } from '../models/aml/FileDTO';
import { ActiveTCTermGroupWithSectionResponseDto } from '../models/terms_conditions/ActiveTCTermGroupWithSectionResponseDto';
import { TCTermGroupDto } from '../models/terms_conditions/TCTermGroupDto';

export class TermsConditionService {
  static async findAllByEntityId(
    entityId: number | undefined
  ): Promise<ActiveTCTermGroupWithSectionResponseDto> {
    const response = await axios.get<ActiveTCTermGroupWithSectionResponseDto>(
      `/terms-condition/tc-term_group/list/${entityId}`
    );

    const data = response.data;

    return data;
  }

  static async createTermGroup(tcTermGroupDto: TCTermGroupDto): Promise<void> {
    await axios.post('/terms-condition/tc-term_group/create', tcTermGroupDto);
  }

  static async editTermGroup(
    id: number,
    tcTermGroupDto: TCTermGroupDto
  ): Promise<void> {
    await axios.put(
      `/terms-condition/tc-term_group/edit/${id}`,
      tcTermGroupDto
    );
  }

  static async findAllByEntityIdAndClientToken(
    entityId: number | string | undefined,
    clientToken: string | undefined
  ): Promise<Array<ActiveTCTermGroupWithSectionResponseDto>> {
    const response = await axios.get<Array<ActiveTCTermGroupWithSectionResponseDto>>(`/terms-condition/tc-term_group/public/list/${entityId}/${clientToken}`);
    const data = response.data;
    return data?.map(
      (group) => new ActiveTCTermGroupWithSectionResponseDto(group)
    );
  }

  static async getFilesForTermsAndConditions(): Promise<FileDTO[]> {
    const response = await axios.get('/file/public/token-files');
    return response.data;
  }
}
